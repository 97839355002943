import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby"
import Seo from "../components/seo";

const NotFoundPage = () => (
    <Layout>
        <div className="content" style={{height: "484px"}}>
            <section>
                <div className="container">
                    <div className="section">
                        <div className="columns">
                            <div className="column is-10 is-offset-1">
                                <h1>Die Seite konnte leider nicht gefunden werden.</h1>
                                <p>404 (NOT FOUND)</p>
                                <p>
                                    Bitte überprüfen Sie Ihre Eingabe oder besuchen Sie unsere <strong><Link to="/">Gastrokalk Homepage</Link></strong>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
);

export default NotFoundPage;

export const Head = () => <Seo title="Nicht Gefunden" />
